import { createApp } from 'vue'
import App from './App.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import router from './router'

import Vue3Lottie from 'vue3-lottie'

library.add(fas)

import VueMeta from 'vue-meta'

export default {
    components: {
        Vue3Lottie,
    },
}

createApp(App)
    .component('fa', FontAwesomeIcon)
    .use(router, VueMeta).mount('#app')
