import { createRouter, createWebHistory } from 'vue-router'
// import Index from "../views/Index.vue"

const routes = [
    {
        name: 'Index',
        path: '/',
        component: () => import('../pages/Home.vue'),
        meta: {
            title: 'Arnaud, votre créateur web dans les landes !',
        },
        lastmod: '2022-07-09',
        priority: 1.0,
        changefreq: 'monthly'
    },
    {
        name: 'Function',
        path: '/function',
        component: () => import('../pages/FunctionService.vue'),
        meta: {
            title: 'Trouvez le service dont vous avez besoin.',
        },
        lastmod: '2022-07-09',
        priority: 0.8,
        changefreq: 'yearly'
    },
    {
        name: 'ConceptionWeb',
        path: '/conceptionweb',
        component: () => import('../pages/services/ConceptionWeb.vue'),
        meta: {
            title: 'Trouvez le service dont vous avez besoin.',
        },
        lastmod: '2022-07-09',
        priority: 0.7,
        changefreq: 'yearly'
    },
    {
        name: 'Marketing',
        path: '/marketing',
        component: () => import('../pages/services/MarketingNumerique.vue'),
        meta: {
            title: 'Trouvez le service dont vous avez besoin.',
        },
        lastmod: '2022-07-09',
        priority: 0.7,
        changefreq: 'yearly'
    },
    {
        name: 'Analytics',
        path: '/analytics',
        component: () => import('../pages/services/WebAnalytics.vue'),
        meta: {
            title: 'Trouvez le service dont vous avez besoin.',
        },
        lastmod: '2022-07-09',
        priority: 0.7,
        changefreq: 'yearly'
    },
    {
        name: 'Realisations',
        path: '/realisations',
        component: () => import('../pages/Realisation.vue'),
        meta: {
            title: 'Réalisations → rejoignez l\'aventure avec votre projet',
        },
        lastmod: '2022-07-09',
        priority: 0.8,
        changefreq: 'monthly'
    },
    {
        name: 'Contact',
        path: '/contact',
        component: () => import('../pages/Contact.vue'),
        meta: {
            title: 'On s\'appelle on se fait une bouffe ?',
        },
        lastmod: '2022-07-09',
        priority: 0.8,
        changefreq: 'yearly'
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
